import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getCotacaoUSDTBRL() {
      return new Promise((resolve, reject) => {
        axios.get('https://api.binance.com/api/v3/ticker/price?symbol=USDTBRL')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getPosicoes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/posicao', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getPosicoesResumo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/posicao/resumo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getUltimasPosicoesFinalizadas() {
      return new Promise((resolve, reject) => {
        axios.get('fut/posicao/fechada/ultimas')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getFinanceiroResumoHome() {
      return new Promise((resolve, reject) => {
        axios.get('fut/relatorio/desempenho-home')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
